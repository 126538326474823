
import { StoreService } from '@services/store.service';
export const GamesPlugin = {
  /**
   * The priority of the plugin relative to other plugins that determines call
   * order. Higher numbers have higher priority. If not specified it will
   * default to a priority of 0.
   */
  priority: 0,
  preRequest: function (requestArgs: any) {
    const search = requestArgs.url?.match(/%24search=\w*\d*/)
    if (search?.input) {
      const game = search?.input.split('search=')[1]
      const filterJson = requestArgs.url.match(/filterJson.*}/)

      if (filterJson) {
        const upd = filterJson[0].replace(/\W$/gm, `,"{dt}.name":{"$like":"%25${game}%25","$ignoreCase": true}}`)
        requestArgs.url = requestArgs.url.replace(/filterJson=.*}/gm, upd)
      }
    }
    // {"{dt}.name":{"$like":"Virtual%25"}}
    if (requestArgs.url) {
      requestArgs.url = requestArgs.url
        .replace(/skip=/gm, "offset=")
        .replace(/sort=/gm, "orderBy=")
    }

  },
  request: function (args: any) {
    console.log(args);
    // You can alter the response by changing null to the response you wish to make.
    return Promise.resolve(20);
  },
  /**
   * An initialization function called when registered with Formio.
   *
   * @param Formio - The static class of the Form.io SDK
   */
  init: (Formio: any) => { },

  /**
   * A deregistration function called when deregistering with Formio
   *
   * @param Formio - The static class of the Form.io SDK
   */
  deregister: (Formio: any) => {
    // console.log(Formio)
  },
};

// const crypt = (salt, text) => {
//   const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
//   const byteHex = (n) => ('0' + Number(n).toString(16)).substr(-2);
//   const applySaltToChar = (code) =>
//     textToChars(salt).reduce((a, b) => a ^ b, code);

//   return text
//     .split('')
//     .map(textToChars)
//     .map(applySaltToChar)
//     .map(byteHex)
//     .join('');
// };
